<script>
import CMService from '../core/cm-service'
export default {
  name: 'ServiceProvider',
  render() {
    return this.$slots.default
  },
  data: () => ({
    svc: new CMService()
  }),
  provide() {
    return {
      svc: this.svc
    }
  },
}
</script>